/* font import */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

/* font-family: 'Poppins', sans-serif; */

/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}
/* Remove default margin */
* {
  margin: 0;
  padding: 0;
  font: inherit;
}
/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role="list"],
ol[role="list"] {
  list-style: none;
}
ul {
  list-style: none;
  list-style-type: none;
}
/* Set core root defaults */
html:focus-within {
  scroll-behavior: smooth;
}
html {
  color-scheme: dark light;
}
html,
body {
  height: 100%;
}
/* Set core body defaults */
body {
  min-height: 100vh;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}
a {
  text-decoration: none;
}

/* Make images easier to work with */
img,
picture,
svg,
video {
  max-width: 100%;
  display: block;
}

/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }

  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}
body {
  background-color: #eef5ff;
  font-family: "Poppins", sans-serif;
  color: #000000;
  height: 100vh;
}
.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  position: relative;
}
nav {
  position: absolute;
  width: 100%;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
}

/* bmi calcu */
.calculator {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.calculator h2 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}
.bmi-calc-holder {
  background-color: #f6bd1a;
  width: fit-content;
  padding: 2rem 0.8rem;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.bmi-unit {
  display: flex;
  gap: 0.5rem;
}
.bmi-calcu {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.height-value {
  display: flex;
}
.value-box {
  display: flex;
}

.unit,
.result-unit {
  display: flex;
  font-size: 0.85rem;
}
.unit-value {
  width: 110px;
  background-color: #ffffff;
  border: 1px solid #000000;
  padding: 1px 0.2rem;
  height: 1.5rem;
  color: #000000;
  border-radius: 0.5rem;
  font-size: 0.8rem;
}

.height-ft {
  gap: 2px;
}
.height-ft .unit-value {
  width: 50px;
}
.unit-dropdown {
  background-color: #ffffff;
  border: 1px solid #000000;
  padding: 1px 0.2rem;
  color: #000000;
  height: 1.5rem;
  border-radius: 0.5rem;
  font-size: 0.8rem;
}
.value-box {
  display: flex;
  gap: 0.1rem;
  justify-content: center;
  align-items: center;
}
.value-box span {
  font-size: 0.8rem;
}
.bmi-calcu-btn button {
  width: 100%;
  background-color: #246afe;
  padding: 0.2rem 2rem;
  border: none;
  outline: none;
  border-radius: 8px;
  color: #ffffff;
}
.bmi-result-unit {
  display: flex;
  gap: 0.8rem;
}
.result-unit {
  background-color: #ffffff;
  border: 1px solid #000000;
  font-size: 0.8rem;
  padding: 1px 0.5rem;
  border-radius: 0.5rem;
}
.bmi-result {
  display: flex;
  gap: 1rem;
  flex-direction: column;
}
/* tablet
 */
@media (min-width: 760px) {
  /* bmi */
  .calculator h2 {
    font-size: 2.8rem;
    margin-bottom: 1rem;
  }
  .bmi-calc-holder {
    padding: 2rem;
  }
  .bmi-unit {
    gap: 1rem;
  }
  .unit,
  .result-unit {
    font-size: 1rem;
  }
  .unit-value {
    width: 100px;
    padding: 0.2rem 0.8rem;
    height: auto;
    font-size: 1rem;
  }
  .height-ft .unit-value {
    width: 80px;
  }
  .unit-dropdown {
    padding: 0.2rem 0.8rem;
    height: auto;
    font-size: 1rem;
  }
  .value-box {
    gap: 0.5rem;
  }
  .value-box span {
    font-size: 1rem;
  }
  .height-ft {
    gap: 1rem;
  }
  .bmi-calcu-btn button {
    padding: 0.5rem 2rem;
  }
  .result-unit {
    padding: 0.2rem 0.8rem;
    height: auto;
    font-size: 1rem;
  }
}
